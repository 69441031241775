<!--
 * @Description: 登录组件
 * @Date: 2020-02-19 20:55:17
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-01 15:34:08
 -->
<template>
  <div id="myLogin">
    <el-dialog title="登录" width="500px" center :visible.sync="isLogin">
      <el-form
        :model="loginForm"
        :rules="rules"
        status-icon
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"><i class="el-icon-user"></i> 账号登录</span>
            <el-form-item prop="username">
              <el-input
                prefix-icon="el-icon-user-solid"
                placeholder="请输入账号"
                v-model="loginForm.username"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                prefix-icon="el-icon-view"
                type="password"
                placeholder="请输入密码"
                v-model="loginForm.password"
              ></el-input>
              <div style="position: relative; top: 5px;text-align: right;">
                <a
                  href="http://oss.toysmodel.cn:11180/reg/#/reset"
                  target="_blank"
                  >忘记密码?</a
                >
              </div>
            </el-form-item>
            <el-form-item>
              <el-button
                size="medium"
                type="primary"
                @click="doLogin"
                style="width: 100%"
                >登录</el-button
              >
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="手机登录">
            <span slot="label"><i class="el-icon-mobile"></i> 手机登录</span>
            <el-form-item prop="mobile">
              <el-input
                prefix-icon="el-icon-mobile"
                placeholder="请输入手机号"
                v-model="loginForm.mobile"
              ></el-input>
            </el-form-item>
            <el-form-item prop="verify">
              <el-row :gutter="10">
                <el-col :span="16"
                  ><el-input
                    prefix-icon="el-icon-s-promotion"
                    placeholder="请输入验证码"
                    v-model="loginForm.verify"
                  ></el-input
                ></el-col>
                <el-col :span="8">
                  <el-button
                    size="medium"
                    type="warning"
                    @click="sendVerify"
                    :disabled="verifyBtnDisable"
                    >获取验证码</el-button
                  >
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button
                size="medium"
                type="primary"
                @click="doVerifyLogin"
                style="width: 100%"
                >登录</el-button
              >
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "MyLogin",
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      this.$refs.ruleForm.validateField("checkPass");
      return callback();
    };
    return {
      verifyBtnDisable: false,
      timer: 60,
      verifyNum: "",
      loginForm: {
        username: "",
        password: "",
        mobile: "",
        verify: "",
      },
      rules: {
        username: [{ validator: validateName, trigger: "blur" }],
      },
    };
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.$refs["ruleForm"].resetFields();
        this.setShowLogin(val);
      },
    },
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),

    /**
     * 账号密码登录
     */
    doLogin() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$axios
            .post(this.$apiBaseUrl + "/api/617ce534c6a63", {
              username: this.loginForm.username,
              password: this.loginForm.password,
              dev_id: window.localStorage.getItem("ip"),
              dev_name: window.localStorage.getItem("ip"),
              app_version: "1.0.0",
              version: "1.0.0",
            })
            .then((res) => {
              if (res.data.code === 1) {
                // 隐藏登录组件
                this.isLogin = false;
                // 登录信息存到本地
                let user = JSON.stringify(res.data.data);
                localStorage.setItem("user", user);
                // 登录信息存到vuex
                this.setUser(res.data.data);
                // 弹出通知框提示登录成功信息
                this.notifySucceed(res.data.msg);
              } else {
                // 清空输入框的校验状态
                this.$refs["ruleForm"].resetFields();
                // 弹出通知框提示登录失败信息
                this.notifyError(res.data.msg);
              }
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    },

    /**
     * 发生验证码
     */
    async sendVerify() {
      if (!this.loginForm.mobile && this.loginForm.mobile.length != 11) {
        this.notifyError("请输入正确的手机号!");
      } else {
        const { data } = await this.$axios.get(
          this.$apiBaseUrl +
            "/api/648bd804786e6?mobile=" +
            this.loginForm.mobile
        );
        this.notifySucceed("验证码已发送!");
        if (data.code === 1) {
          this.verifyNum = data.data;
        } else {
          this.notifyError(data.msg);
        }
        this.verifyBtnDisable = true;
        setTimeout(() => {
          this.verifyBtnDisable = false;
        }, 60000);
      }
    },

    doVerifyLogin() {
      if (this.loginForm.verify && this.loginForm.verify == this.verifyNum) {
        this.$axios
          .post(this.$apiBaseUrl + "/api/5e24110ec3cf3", {
            mobile: this.loginForm.mobile,
            verify: this.loginForm.verify,
            dev_id: window.localStorage.getItem("ip"),
            dev_name: window.localStorage.getItem("ip"),
            app_version: "1.0.0",
            version: "1.0.0",
          })
          .then((res) => {
            if (res.data.code === 1) {
              this.isLogin = false;
              let user = JSON.stringify(res.data.data);
              localStorage.setItem("user", user);
              this.setUser(res.data.data);
              this.notifySucceed(res.data.msg);
            } else {
              this.$refs["ruleForm"].resetFields();
              this.notifyError(res.data.msg);
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      } else {
        this.notifyError("请输入正确的验证码");
      }
    },
  },
};
</script>
<style></style>
