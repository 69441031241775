<!--
 * @Description: 列表组件，用于我的收藏的商品列表
 -->
<template>
  <div id="myList" class="myList">
    <ul>
      <li v-for="item in list" :key="item.ID">
        <el-popover placement="top">
          <p>确定删除吗？</p>
          <div style="text-align: right; margin: 10px 0 0">
            <el-button
              type="primary"
              size="mini"
              @click="deleteCollect(item.ID)"
              >确定</el-button
            >
          </div>
          <i
            class="el-icon-close delete"
            slot="reference"
            v-show="isDelete"
          ></i>
        </el-popover>
        <router-link :to="{ path: '/detail', query: { id: item.ID } }" target="_blank">
          <img :src="item.Picture" alt />
          <h2>{{ item.PName }}</h2>
          <h3>{{ item.SName }}</h3>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyList",
  // list为父组件传过来的商品列表
  props: ["list", "isDelete"],
  data() {
    return {};
  },
  methods: {
    /**
     * 删除收藏
     * @param {*} goods_id
     */
    async deleteCollect(goods_id) {
      await this.$axios.post(this.$apiBaseUrl + '/api/6087db4edf7ee', {
        uid: this.$store.getters.getUser.id,
        pid: goods_id,
      });
      for (let i = 0; i < this.list.length; i++) {
        const temp = this.list[i];
        if (temp.ID == goods_id) {
          this.list.splice(i, 1);
        }
      }
      // 提示删除成功信息
      this.notifySucceed("删除成功!");
    },
  },
};
</script>
<style scoped>
.myList ul li {
  z-index: 1;
  float: left;
  width: 234px;
  height: 255px;
  padding: 10px 0;
  margin: 0 0 14.5px 13.7px;
  background-color: white;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
}
.myList ul li:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}
.myList ul li img {
  display: block;
  width: 210px;
  height: 210px;
  background: url(../assets/imgs/placeholder.png) no-repeat 50%;
  margin: 0 auto;
}
.myList ul li h2 {
  margin: 5px 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.myList ul li h3 {
  margin: 5px 10px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.myList ul li p {
  margin: 10px 10px 10px;
  text-align: center;
  color: #ff6700;
}
.myList ul li p .del {
  margin-left: 0.5em;
  color: #b0b0b0;
  text-decoration: line-through;
}
.myList #more {
  text-align: center;
  line-height: 280px;
}
.myList #more a {
  font-size: 18px;
  color: #333;
}
.myList #more a:hover {
  color: #ff6700;
}
.myList ul li .delete {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}
.myList ul li:hover .delete {
  display: block;
}
.myList ul li .delete:hover {
  color: #ff6700;
}
</style>