<template>
  <div class="rightFixedMenu">
    <div class="menuItem" style="padding: 10px 10px">
      <el-tooltip
        placement="left-start"
        effect="light"
        popper-class="popContent"
      >
        <div slot="content">
          <div style="padding: 8px 8px">
            <div style="font-size: 14px; color: #333; font-weight: bold">
              在线客服-微信:
            </div>
            <div class="serviceMethod">
              <div style="width: 150px; text-align: center; cursor: pointer">
                <el-tooltip placement="top">
                  <div slot="content">
                    <img
                      :src="require('@/assets/imgs/wxkf.png')"
                      style="width: 160px; height: 160px"
                    />
                  </div>
                  <div>
                    <img
                      :src="require('@/assets/imgs/wxkf.png')"
                      style="width: 130px; height: 130px"
                    />
                  </div>
                </el-tooltip>
                <div style="line-height: 20px">
                  <el-button
                    style="
                      background: rgb(14, 181, 94);
                      border-color: rgb(14, 181, 94);
                      padding-top: 8px;
                      padding-bottom: 8px;
                    "
                    ><a
                      href="https://work.weixin.qq.com/kfid/kfc502514268be0b712"
                      size="mini"
                      style="text-decoration: none; color: #fff"
                      target="_blank"
                      >联系客服</a
                    ></el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <img
              :src="require('@/assets/imgs/guest.png')"
              style="width: 15px"
            />
          </div>
          <div style="line-height: 20px">客服</div>
        </div>
      </el-tooltip>
    </div>
    <div class="menuItem">
      <el-tooltip placement="left-start" effect="light" popper-class="popContent">
        <div slot="content">
          <div style="padding: 8px 8px 0 8px">
            <div style="font-size: 14px; color: #333; font-weight: bold">
              您好，{{ this.userInfo.id ? this.userInfo.username : "请登录" }}:
            </div>
            <div class="serviceMethod">
              <div
                style="width: 120px; text-align: center; cursor: pointer"
                @click="gotoStore"
              >
                <div>
                  <img :src="require('@/assets/imgs/waiting-pay.png')" />
                </div>
                <div style="line-height: 20px">商品收藏</div>
              </div>
              <div
                style="width: 120px; text-align: center; cursor: pointer"
                @click="gotoStore"
              >
                <div>
                  <img :src="require('@/assets/imgs/waiting-shop.png')" />
                </div>
                <div style="line-height: 20px">品牌收藏</div>
              </div>
            </div>
            <div style="text-align: center; margin: 10px auto">
              <el-button
                size="mini"
                type="danger"
                style="width: 150px"
                @click="gotoUcenter"
                >进入会员中心</el-button
              >
            </div>
          </div>
        </div>
        <div>
          <div>
            <img
              :src="require('@/assets/imgs/ucenter.png')"
              style="width: 15px"
            />
          </div>
          <div>会员中心</div>
        </div>
      </el-tooltip>
    </div>
    <div class="menuItem">
      <div>购物车</div>
      <div>({{ this.getNum }})</div>
    </div>
    <div class="menuItem">
      <el-tooltip placement="left-start" effect="light" popper-class="popContent">
        <div slot="content">
          <div style="padding: 0 8px 0 8px">
            <div style="font-size: 14px; color: #333; font-weight: bold">
              无消息
            </div>
          </div>
        </div>
        <div>
          <div>
            <img
              :src="require('@/assets/imgs/message.png')"
              style="width: 15px"
            />
          </div>
          <div>消息</div>
        </div>
      </el-tooltip>
    </div>
    <div class="menuItem">
      <el-tooltip placement="left-start" effect="light" popper-class="popContent">
        <div slot="content">
          <div style="padding: 0 8px 0 8px">
            <div style="text-align: center; font-size: 14px; font-weight: bold">
              <div style="margin: 0 auto; width: 140px; line-height: 30px">
                绒博汇APP
              </div>
              <img
                :src="require('@/assets/imgs/qrcode1.png')"
                style="width: 140px"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <img :src="require('@/assets/imgs/code.png')" style="width: 15px" />
          </div>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CustomerService",
  data() {
    return {
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters(["getNum"]),
  },
  mounted() {
    this.userInfo = this.$store.getters.getUser;
  },
  methods: {
    /**
     * 进入我的收藏
     */
    gotoStore() {
      if (!this.userInfo.id) {
        this.$store.dispatch("setShowLogin", true);
        return false;
      }
      this.$router.push("/collect");
    },
    /**
     * 进入会员中心
     */
    gotoUcenter() {
      if (!this.userInfo.id) {
        this.$store.dispatch("setShowLogin", true);
        return false;
      }
      this.$router.push("/ucenter");
    },
  },
};
</script>

<style scoped>
.rightFixedMenu {
  position: fixed;
  top: 30%;
  right: 0;
  width: 35px;
  border: 1px solid #f7cbce;
  font-size: 12px;
  font-weight: bold;
}
.menuItem {
  padding: 5px 10px;
  text-align: center;
  border-bottom: 1px solid #f7cbce;
  cursor: pointer;
}
.menuItem:hover {
  background: #ff414d;
  color: #fff;
}
.popContent {
  border: 1px solid #ff414d !important;
}
.serviceMethod {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.serviceMethod img {
  width: 30px;
  height: 30px;
}
</style>