<!-- 底部 -->
<template>
  <el-footer>
    <div class="footer">
      <el-row style="height: 45px"> &nbsp;</el-row>
      <!-- 主要要点开始 -->
      <el-row class="slogan">
        <el-col :span="6">
          <div>
            <el-image :src="require('@/assets/imgs/ytst.png')"></el-image>
            <span>以图搜款</span>
          </div>
          <div class="slogan-text">以图搜款，找货更便捷</div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-image :src="require('@/assets/imgs/sxtx.png')"></el-image>
            <span>上新提醒</span>
          </div>
          <div class="slogan-text">关注公众号，实时了解档口上新情况</div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-image :src="require('@/assets/imgs/scdh.png')"></el-image>
            <span>市场导航</span>
          </div>
          <div class="slogan-text">关注公众号，实时了解档口上新情况</div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-image :src="require('@/assets/imgs/ptdt.png')"></el-image>
            <span>全平台打通</span>
          </div>
          <div class="slogan-text">关注公众号，实时了解档口上新情况</div>
        </el-col>
      </el-row>
      <!-- 主要要点结束 -->
      <!-- 常用搜索开始 -->
      <el-row class="search-keyword">
        <el-col :span="24" style="height: 30px; border: 1px solid rgb(233, 233, 233);border-left: 0; border-right: 0;"
          >&nbsp;</el-col
        >
      </el-row>
      <!-- 常用搜索结束 -->
      <!-- 关于我们开始 -->
      <el-row class="about">
        <el-col :span="8" :offset="8">
          <el-row>
            <el-col :span="6">关于我们</el-col>
            <el-col :span="6">联系我们</el-col>
            <el-col :span="6">隐私条款</el-col>
            <el-col :span="6">资讯中心</el-col>
          </el-row>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <!-- 关于我们结束 -->
      <!-- CopyRight开始 -->
      <el-row class="copyright">
        <el-col :span="8" :offset="8">
          <p>Copyright © 2013 - 现在 扬州博来软件科技有限公司</p>
          <p style="display: inline-block">
            <a href="https://beian.miit.gov.cn" target="_black"
              >ICP备案号：苏ICP备14040787号-1</a
            >
          </p>
          <p
            style="
              display: inline-block;
              margin-left: 15px;
              position: relative;
              top: 5px;
            "
          >
            <a href="http://www.beian.gov.cn" target="_black"
              ><img :src="require('@/assets/imgs/gaba.png')" />
              <p
                style="
                  display: inline-block;
                  position: relative;
                  top: -5px;
                  padding-left: 5px;
                "
              >
                苏公网安备 32100302010595号
              </p></a
            >
          </p>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <!-- CopyRight结束 -->
    </div>
  </el-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
/* 底栏容器CSS */
.footer {
  background: #ececec;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}
.footer .slogan,
.footer .search-keyword,
.footer .about,
.footer .copyright {
  width: 1320px;
  margin: 0 auto;
}
.footer .slogan span {
  font-size: 18px;
  line-height: 26px;
  color: #3c3c3c;
  font-weight: bold;
  padding-left: 5px;
  position: relative;
  top: -6px;
}
.footer .slogan .slogan-text {
  color: #999;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}
.footer .about,
.footer .copyright {
  text-align: center;
  margin-top: 26px;
  font-size: 12px;
  line-height: 150%;
  color: #3e3e3e;
}
.footer .copyright a {
  line-height: 40px;
  height: 40px;
}
/* 底栏容器CSS END */
</style>
