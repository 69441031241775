<template>
  <div id="register">
    <el-dialog title="企业认证" width="800px" center :visible.sync="showDialog">
      <el-form
        :model="supplyForm"
        :rules="rules"
        status-icon
        label-width="120px"
        ref="supplyForm"
      >
        <el-form-item label="门店名称" prop="name">
          <el-select
            v-model="supplyForm.name"
            filterable
            clearable
            placeholder="请选择门店"
            style="min-width: 500px"
            @change="handleChange"
          >
            <el-option
              v-for="item in supplyOptions"
              :key="item.ID"
              :label="item.CName"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称" prop="company_name">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入姓名"
            v-model="supplyForm.company_name"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobile_prefix">
              <el-input
                prefix-icon="el-icon-mobile"
                readonly
                v-model="supplyForm.mobile_prefix"
                 placeholder="手机号码前七位"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="mobile_number">
              <el-input v-model="supplyForm.mobile_number"  placeholder="手机号码后四位"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="营业执照" prop="buiness_image">
          <el-upload
            class="avatar-uploader"
            action="https://erp.toysmodel.cn:11443/api/upload/do_upload"
            :show-file-list="false"
            :on-progress="handleUploadProgress"
            :on-success="handleUploadBuinessSuccess"
          >
            <img v-if="imageList.businessUrl" :src="imageList.businessUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="法人身份证正面">
          <el-upload
            class="avatar-uploader"
            action="https://erp.toysmodel.cn:11443/api/upload/do_upload"
            :show-file-list="false"
            :on-progress="handleUploadProgress"
            :on-success="handleUploadCertZSuccess"
          >
            <img v-if="imageList.certZUrl" :src="imageList.certZUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="法人身份证反面">
          <el-upload
            class="avatar-uploader"
            action="https://erp.toysmodel.cn:11443/api/upload/do_upload"
            :show-file-list="false"
            :on-progress="handleUploadProgress"
            :on-success="handleUploadCertFSuccess"
          >
            <img v-if="imageList.certFUrl" :src="imageList.certFUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
            size="medium"
            type="primary"
            @click="doSave"
            style="width: 100%"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Supply",
  props: ["show_supply_dialog"],
  data() {
    /**
     * 手机号验证
     * @param {*} rule
     * @param {*} value
     * @param {*} callback
     */
    const validateMobile = (rule, value, callback) => {
      if (
        this.supplyForm.mobile_number != "" &&
        value === this.supplyForm.mobile_right
      ) {
        this.$refs.supplyForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("手机号码不正确，请确认补充正确的手机号!"));
      }
    };

    return {
      showDialog: false, // 控制注册组件是否显示
      supplyOptions: [],
      imageList: {
        businessUrl: "",
        certZUrl: "",
        certFUrl: "",
      },
      supplyForm: {
        sid: 0,
        name: "",
        company_name: "",
        mobile: "",
        mobile_prefix: "",
        mobile_right: "",
        mobile_number: "",
        business_image: "",
        cert: [],
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ required: true, message: "请选中门店", trigger: "blur" }],
        mobile_number: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
        business_image: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getSupplyData();
  },
  watch: {
    /**
     * 监听父组件传值，控制弹窗显示
     * @param {*} val
     */
    show_supply_dialog: function (val) {
      if (val) {
        this.showDialog = val;
      }
    },
    /**
     * 监听子组件变化回传父组件，控制弹窗显示
     * @param {*} val
     */
    showDialog: function (val) {
      if (!val) {
        this.$refs["supplyForm"].resetFields();
        this.$emit("fromChild", val);
      }
    },
  },
  methods: {
    /**
     * 获取企业数据
     */
    async getSupplyData() {
      const { data } = await this.$axios.get(
        this.$newApi + "/api/v2/supply/name"
      );
      this.supplyOptions = data.data;
    },
    /**
     * select切换
     * @param {*} value
     */
    handleChange(value) {
      this.supplyForm.sid = value;
      const selectItem = this.supplyOptions.find((item) => {
        return item.ID == value;
      });
      if (selectItem) {
        const mobile = selectItem.Mobile;
        this.supplyForm.mobile_prefix = mobile.substring(0, 7);
        this.supplyForm.mobile_right = mobile.substring(7);
      }
    },
    /**
     * 上传中
     */
    handleUploadProgress() {
      this.notifySucceed("上传中!");
    },
    /**
     * 营业执照上传完成
     * @param {*} response
     * @param {*} file 
     */
    handleUploadBuinessSuccess(response, file) {
      const data = response.data;
      if (data) {
        this.imageList.businessUrl = URL.createObjectURL(file.raw);
        this.supplyForm.business_image = data[0].path;
      }
      this.notifySucceed("上传成功!");
    },
    /**
     * 身份证正面上传完成
     * @param {*} response
     * @param {*} file 
     */
    handleUploadCertZSuccess(response, file) {
      const data = response.data;
      if (data) {
        this.imageList.certZUrl = URL.createObjectURL(file.raw);
        this.supplyForm.cert[0] = data[0].path;
      }
      this.notifySucceed("上传成功!");
    },
    /**
     * 身份证反面上传完成
     * @param {*} response
     * @param {*} file 
     */
    handleUploadCertFSuccess(response, file) {
      const data = response.data;
      if (data) {
        this.imageList.certFUrl = URL.createObjectURL(file.raw);
        this.supplyForm.cert[1] = data[0].path;
      }
      this.notifySucceed("上传成功!");
    },
    /**
     * 保存提交
     */
    doSave() {
      this.$refs["supplyForm"].validate((valid) => {
        if (valid) {
          if (!this.supplyForm.cert[0]) {
            this.notifyError("请上传身份证正面!");
            return false;
          }
          if (!this.supplyForm.cert[1]) {
            this.notifyError("请上传身份证反面!");
            return false;
          }
          const post = this.getPostData();
          this.$axios
            .post(this.$newApi + "/api/v2/supply/extend/save", post)
            .then((response) => {
              if (response.data.code == 1) {
                this.notifySucceed("提交成功!");
                this.showDialog = false;
              } else {
                this.notifyError(response.data.msg);
              }
            });
        }
      });
    },
    /**
     * 获取提交的数据
     */
    getPostData() {
      let companyName = this.supplyForm.company_name;
      if (!companyName) {
        companyName = this.supplyOptions.find((item) => {
          return item.ID == this.supplyForm.sid;
        }).CName;
      }
      return {
        sid: this.supplyForm.sid,
        company_name: companyName,
        business_image: this.supplyForm.business_image,
        cert_images: this.supplyForm.cert.join(","),
      };
    },
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px !important;
  text-align: center;
}
.avatar-uploader .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
