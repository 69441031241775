/*
 * @Description: 路由配置
 */
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  // 首页
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../components/Error.vue"),
  },
  // 商家列表
  {
    path: "/shopList",
    name: "ShopList",
    component: () => import("../views/ShopList.vue"),
  },
  // 商家商品
  {
    path: "/shop",
    name: "Shop",
    component: () => import("../views/Shop.vue"),
  },
  // 代发商品
  {
    path: "/df",
    name: "Df",
    component: () => import("../views/Daifa.vue"),
  },
  // 全部商品
  {
    path: "/goods",
    name: "Goods",
    component: () => import("../views/Goods.vue"),
  },
  // 搜索
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  // 关于
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  // 软件下载
  {
    path: "/software",
    name: "Software",
    component: () => import("../views/Software.vue"),
  },
  // 商品详情
  {
    path: "/detail",
    name: "Detail",
    component: () => import("../views/GoodsDetail.vue"),
  },
  // 确认订单
  {
    path: "/confirmOrder",
    name: "ConfirmOrder",
    component: () => import("../views/ConfirmOrder.vue"),
    meta: {
      requireAuth: true, // 需要验证登录状态
    },
  },
  // 会员中心
  {
    path: "/ucenter",
    name: "UserGold",
    component: () => import("../views/UCenter/Index.vue"),
    meta: {
      requireAuth: true, // 需要验证登录状态
    },
  },
  // 购物车
  {
    path: "/shoppingCart",
    name: "ShoppingCart",
    component: () => import("../views/ShoppingCart.vue"),
    meta: {
      requireAuth: true, // 需要验证登录状态
    },
  },
  // 用户收藏
  {
    path: "/collect",
    name: "Collect",
    component: () => import("../views/Collect.vue"),
    meta: {
      requireAuth: true, // 需要验证登录状态
    },
  },
  // 用户订单
  {
    path: "/order",
    name: "Order",
    component: () => import("../views/UCenter/Order.vue"),
    meta: {
      requireAuth: true, // 需要验证登录状态
    },
  },
  // 用户订单详情
  {
    path: "/orderInfo",
    name: "OrderInfo",
    component: () => import("../views/UCenter/OrderInfo.vue"),
    meta: {
      requireAuth: true, // 需要验证登录状态
    },
  },
  // 用户金币记录
  {
    path: "/gold",
    name: "Gold",
    component: () => import("../views/Gold.vue"),
    meta: {
      requireAuth: true, // 需要验证登录状态
    },
  },
  // 用户须知
  {
    path: "/readme",
    name: "ReadMe",
    component: () => import("../views/ReadMe.vue"),
    meta: {
      requireAuth: false, // 需要验证登录状态
    },
  },
];

const router = new Router({
  // base: '/dist',
  // mode: 'history',
  routes,
});

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
