<!-- 顶部导航条 -->
<template>
  <div>
    <!-- 顶部导航栏 -->
    <div class="topbar">
      <el-row class="nav">
        <!-- 顶部左侧导航 -->
        <el-col :span="2"><i class="el-icon-mobile-phone"></i>手机版</el-col>
        <el-col :span="3" class="welcome"
          >欢迎来到<span style="color: #e5272b">绒博汇</span>官网</el-col
        >
        <el-col :span="6" class="welcome" v-if="!this.$store.getters.getUser">
          <el-button type="text" style="color: #666" @click="login"
            >请登录</el-button
          >
          <span class="sep">|</span>
          <el-button type="text" style="color: #e5272b" @click="register = true"
            >注册</el-button
          >
        </el-col>
        <el-col :span="6" class="usercenter" v-else>
          <router-link to="/ucenter" target="_blank">
            <span style="color: #e5272b"
              >[ {{ this.$store.getters.getUser.username }} ]</span
            >
          </router-link>
          <a @click="visible = true">[退出]</a>
          <el-dialog title="退出确认" width="30%" :visible.sync="visible">
            <p>确定退出登录吗？</p>
            <div style="text-align: right; margin: 10px 0 0">
              <el-button size="mini" type="text" @click="visible = false"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="logout"
                >确定</el-button
              >
            </div>
          </el-dialog>
        </el-col>
        <!-- 顶部右侧导航 -->
        <el-col :span="8" :offset="5" style="text-align: right">
          <ul>
            <li>
              <router-link to="/ucenter" target="_blank">会员中心<span>|</span></router-link>
            </li>
            <li>
              <router-link to="/order">我的订单<span>|</span></router-link>
            </li>
            <li>
              <router-link to="/collect">我的收藏<span>|</span></router-link>
            </li>
            <li>
              <router-link to="/readme">用户须知</router-link>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <!-- 顶部导航栏END -->
    <!-- 组件为何未注册可以使用? -->
    <!-- 登录模块 -->
    <MyLogin></MyLogin>
    <!-- 注册模块 -->
    <MyRegister :register="register" @fromChild="isRegister"></MyRegister>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "TopBar",
  data() {
    return {
      register: false, // 是否显示注册组件
      visible: false, // 是否退出登录
    };
  },
  created() {
    // 判断用户是否已经登录
    if (
      localStorage.getItem("user") &&
      localStorage.getItem("user") != "undefined"
    ) {
      // 设置vuex登录状态
      this.setUser(JSON.parse(localStorage.getItem("user")));
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (val) {
      if (val === "") {
        // 用户没有登录
        this.setShoppingCart([]);
      } else {
        // 获取购物车信息
        this.$axios
          .post(this.$newApi + "/api/rbh/cart/list", {
            uid: val.id,
          })
          .then((response) => {
            const list = response.data.data.map((item) => {
              let cart = item.goods_info;
              cart.check = false;
              return cart;
            });
            this.setShoppingCart(list);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    },
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
    /**
     * 登录，更改vuex的showLogin值显示登录组件
     */
    login() {
      this.setShowLogin(true);
    },
    /**
     * 退出登录
     */
    logout() {
      this.visible = false;
      // 清空本地登录信息
      localStorage.setItem("user", "");
      // 清空vuex登录信息
      this.setUser("");
      this.notifySucceed("成功退出登录");
    },
    /**
     * 接收注册子组件传过来的数据,显示或隐藏注册控件
     * @param {*} val 
     */
    isRegister(val) {
      this.register = val;
    },
  },
};
</script>

<style scoped>
/* 顶部导航栏CSS */
.topbar {
  height: 40px;
  background-color: #f1f1f1;
}
.topbar .nav {
  width: 1225px;
  margin: 0 auto;
}
.topbar .nav .el-col {
  line-height: 40px;
  color: #666666;
  font-size: 14px;
}
/* 登录用户 */
.topbar .nav .usercenter a {
  color: #666666;
  margin-right: 10px;
}
.topbar .nav .usercenter a:hover {
  cursor: pointer;
  color: #e5272b;
}
.topbar .nav li {
  float: left;
  height: 40px;
  color: #b0b0b0;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
}
.topbar .nav li span {
  margin-left: 10px;
}
.topbar .nav .sep {
  color: #b0b0b0;
  font-size: 12px;
  margin: 0 5px;
}
.topbar .nav li .el-button {
  color: #b0b0b0;
}
.topbar .nav .el-button:hover {
  color: #e5272b;
}
.topbar .nav li a {
  color: #666;
}
.topbar .nav a:hover {
  color: #e5272b;
}
.topbar .nav .shopCart {
  width: 120px;
}
.topbar .nav .shopCart:hover a {
  color: #ff6700;
}
.topbar .nav .shopCart-full {
  width: 120px;
}
.topbar .nav .shopCart-full a {
  color: #e5272b;
}
</style>
